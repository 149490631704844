<template>
  <div>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <v-card outlined>
      <v-card-text class="flex-center">
        <v-card flat :class="$vuetify.breakpoint.smAndDown ? 'w100p' : 'w60p'">
          <v-card-text class="text-left">
            <v-card flat color="OnSurface050" class="mb-8 overflow-hidden">
              <v-card-text class="Medium text-Heading6">系統名稱</v-card-text>
              <v-card tile flat class="pa-4 d-flex">
                <div class="tile_bar OnSurface500 mr-3"></div>
                <p class="mb-0 text-DisplayRegular Medium">CMS</p>
              </v-card>
            </v-card>
            <v-card flat color="OnSurface050" class="mb-8 overflow-hidden">
              <v-card-text class="Medium text-Heading6">目前版本</v-card-text>
              <v-card tile flat class="pa-4 d-flex">
                <div class="tile_bar OnSurface500 mr-3"></div>
                <p class="mb-0 text-DisplayRegular Medium">{{ version }}</p>
              </v-card>
            </v-card>
            <v-card flat color="OnSurface050" class="mb-8 overflow-hidden">
              <v-card-text class="Medium text-Heading6">最新版本</v-card-text>
              <v-card tile flat class="pa-4 d-flex" color="Primary050">
                <div class="tile_bar Primary900 mr-3"></div>
                <p class="mb-0">
                  <span class="text-DisplayRegular Medium Primary900--text">
                    {{ version }}
                  </span>
                  <br />
                  已是最新版本
                </p>
              </v-card>
            </v-card>
          </v-card-text>
        </v-card>
      </v-card-text>
      <SizeBox height="32" />
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      version: localStorage.getItem('fe-version')
    };
  }
};
</script>

<style lang="scss" scoped>
.tile_bar {
  align-self: stretch;
  width: 6px;

  border-radius: 2px;
}
</style>
